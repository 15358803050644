import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { select, Store } from '@ngrx/store';
import {distinctUntilChanged, filter, map, take, takeUntil} from 'rxjs/operators';

import { User } from '../../../shared/models/user/user.model';
import { BaseComponent } from '../../../shared/components/base/base.component';

import { UserService } from '../../../core/modules/user/services/user.service';
import { IAppState } from '../../../core/ngrx';
import { AuthActions, AuthState } from '../../../auth';
import { AuthService } from '../../../auth/services/auth.service';
import {isPlatformBrowser} from "@angular/common";
import {WebHelperService} from "../../../core/services/web-helper/web-helper.service";



@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
})
export class LoginComponent extends BaseComponent implements OnInit {
    public user: User = {} as User;
    public errorMessage = '';
    public positionRedirId = -1;
    public loading: boolean;
    public form: UntypedFormGroup;
    private returnUrl: string;
    authData: AuthState;
    private isBrowser: boolean;

    constructor(
        private readonly userService: UserService,
        private readonly route: ActivatedRoute,
        private readonly store: Store<IAppState>,
        private readonly formBuilder: UntypedFormBuilder,
        private readonly authService: AuthService,
        public readonly webHelper: WebHelperService,
        @Inject(PLATFORM_ID) private platformId
    ) {
        super();

        this.isBrowser = isPlatformBrowser(this.platformId);

        this._buildForm();
    }

    ngOnInit(): void {
        this.returnUrl = this.route.snapshot.queryParams.returnUrl;
        const params = this.route.snapshot.params;
        this.positionRedirId = params.job;

        this.store
            .pipe(
                select(s => s.user.current),
                takeUntil(this.destroy$),
                distinctUntilChanged((prev, curr) => {
                  return JSON.stringify(prev) === JSON.stringify(curr);
                }),
                filter(_ => !!_),
            ).subscribe(user => {
                if (this.positionRedirId > 0) {
                    this.userService.positionRedirId = this.positionRedirId;
                }
            });

        this.store.pipe(
            select(s => s.user.errors),
            map(s => s.filter(err => err.name === 'login' )),
            filter(errors => !!(errors && errors.length)),
            takeUntil(this.destroy$)
        ).subscribe(err => {
            if (err[0].error.status === 429) {
              this.loading = false;
            } else if (err[0].error.status === 400) {
                this.form.controls.email.setErrors({ login: true });
            } else {
                this.form.setErrors({ server: true });
            }

        });

        this.store.pipe(
            select(s => s.auth),
            takeUntil(this.destroy$),
        ).subscribe(res => {
          this.authData = res;
          this.loading = res?.loading;
        });
    }

    public login(): void {
        if (!this.form.valid) {
            return;
        }

        const { email, password } = this.form.value;

        this.store.dispatch(AuthActions.login({
            username: email,
            password,
            attemptsName: 'loginNumber'
        }));
    }

    private _buildForm(): void {
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]],
        });
    }
}
