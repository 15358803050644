import { NgModule } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { MainNavComponent } from '../shared/components/main-nav/main-nav.component';

import { ResetComponent } from './components/reset/reset.component';
import { RegisterComponent } from './components/register/register.component';
import { HelpComponent } from './components/help/help.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterCompanyComponent } from './components/register/register-company.component';
import { SplashComponent } from '../auth/components/splash/splash.component';
import { AuthModule } from '../auth';
import { AuthSimpleRoutingModule } from './auth-simple-routing.module';
import { ErrorComponent } from '../ui/error/error.component';
import { InputComponent } from '../ui/input/input.component';
import { ButtonComponent } from '../ui/button/button.component';
import { RestorePasswordFormComponent } from '../ui/restore-password-form/restore-password-form.component';
import { DropdownComponent } from '../ui/dropdown/dropdown.component';
import { DropdownItemComponent } from '../ui/dropdown-item/dropdown-item.component';
import { PasswordHintComponent } from '../shared/components/password-hint/password-hint.component';
import { ResetInvalidComponent } from './components/reset-invalid/reset-invalid.component';
import { EmptyResultComponent } from '../ui/empty-result/empty-result.component';


@NgModule({
  declarations: [
    ResetComponent,
    RegisterComponent,
    HelpComponent,
    LoginComponent,
    RegisterCompanyComponent,
    SplashComponent,
    ResetInvalidComponent
  ],
  imports: [
    SharedModule,
    NgOptimizedImage,
    AuthSimpleRoutingModule,
    AuthModule,
    MainNavComponent,
    ErrorComponent,
    InputComponent,
    ButtonComponent,
    RestorePasswordFormComponent,
    DropdownComponent,
    DropdownItemComponent,
    PasswordHintComponent,
    EmptyResultComponent
  ],
  exports: [],
})
export class AuthSimpleModule { }
