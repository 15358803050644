<app-main-nav></app-main-nav>

<div class="auth">
  <div class="auth-hero">
    <img class="auth-image" ngSrc="/assets/images/auth_intro.png" width="1280" height="1524" priority alt="">
  </div>

  <div class="auth-data">
    <h1 class="h1">
      {{'FRONT.LOGIN.SIGN_IN' | translate}}
    </h1>

    <form (ngSubmit)="login()"
          [formGroup]="form"
    >
      <div class="field">
        <app-input
          [config]="{
            label: 'FRONT.LOGIN.LOGIN',
            placeholder: 'FRONT.LOGIN.LOGIN',
            required: true,
           }"
          type="email"
          formControlName="email"
        ></app-input>

        <app-error *ngIf="form.get('email').touched && form.get('email').hasError('required')">
          {{'ERRORS.REQUIRED_FIELD' | translate}}
        </app-error>

        <app-error *ngIf="form.get('email').dirty && form.get('email').hasError('email')">
          {{'FRONT.REGISTER.WRONG_EMAIL' | translate}}
        </app-error>

        <app-error *ngIf="form.get('email').hasError('login')">
          {{ 'FRONT.LOGIN.WRONG_CREDENTIALS' | translate }}
        </app-error>
      </div>

      <div class="field">
        <app-input
          [config]="{
            label: 'FRONT.LOGIN.PASSWORD',
            placeholder: 'FRONT.LOGIN.PASSWORD',
            required: true,
            type: 'password'
           }"
          formControlName="password"
        ></app-input>

        <app-error *ngIf="form.get('password').touched && form.get('password').hasError('required')">
          {{'ERRORS.REQUIRED_FIELD' | translate}}
        </app-error>

        <a class="forgot-link"
           [routerLink]="['/hjelp']"
           [queryParams]="{email: this.form.get('email').value}">
          {{'FRONT.LOGIN.FORGOT_PASSWORD' | translate}}
        </a>
      </div>

      <div class="actions">
        <app-button
          class="auth-button"
          [config]="{
            type: 'submit',
            view: 'primary',
            label: 'FRONT.LOGIN.SIGN_IN',
            loading: loading,
            classes: 'width-full'
          }"
        >
        </app-button>
      </div>
    </form>

    <app-error *ngIf="form.hasError('server')">
      {{'ERRORS.SOMETHING_WRONG' | translate}}
    </app-error>

    <app-error *ngIf="authData?.error">
      {{authData.error | translate}}
    </app-error>

  </div>
</div>


